import axios from "axios";

export async function getInfo (upk : string, service : string) {
    try{
        console.log(upk)
        let param = {
             upk ,
            service
        };
        const res =  await axios.post(`https://care.simg.kr/v1/api/careInsu/getData`,
            param)
        return res.data;
    }catch(error){
        console.log(error);
    }
}



