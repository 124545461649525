import React, {useEffect, useState} from 'react';
import MobiSubmit from "../container/mobiSubmit";
import WebSubmit from "../container/webSubmit";
import {useNavigate, useLocation} from "react-router-dom";
import {getParam} from "../utils/common";
import { ExtendedDataTypes} from "../@types";

const Main: React.FC = () => {
    const isMobi = /Mobi/i.test(window.navigator.userAgent);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const key : string|null = searchParams.get('key');
    const [data, setData] = useState<ExtendedDataTypes>({
        upk : '',
        schema : '',
        docType : '',
        check : false
    });
    const navigate = useNavigate();
    useEffect(() => {
        if(key === null || key === '' || key === undefined){
            navigate('/error');
        }else {
            let temp = getParam(key);
            setData({
                upk : temp[0],
                schema : temp[1],
                docType : temp[2],
                check : false
            });
        }
    }, []);
    return (
        <div className='main'>
            {isMobi  ?
                <MobiSubmit data={data} />
                :
                <WebSubmit data={data} />
            }
        </div>
    );
};

export default Main;
