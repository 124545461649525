import React, {SetStateAction, useEffect, useState} from 'react';
import checkOff from '../asset/images/icon_checkbox_16px_off.svg';
import checkOn from '../asset/images/icon_checkbox_16px_on.svg';
import {getInfo} from "../service/getInfo";
import {getDocType} from "../utils/common";
import {ExtendedDataTypes} from "../@types";

interface TextProps {
    data: ExtendedDataTypes; // ExtendedDataTypes 타입을 사용합니다.
    checks: boolean;
    setFilePk : React.Dispatch<SetStateAction<string>>
}

const Text: React.FC<TextProps> = ({ data, checks,setFilePk }) => {
    const isMobi = /Mobi/i.test(window.navigator.userAgent);
    const [info, setInfo] = useState({name : '', filePk: ''});
    useEffect(() => {
        if(data && data.upk){
            getInfo(data.upk, data.schema)
                .then((res) => {
                    setInfo(res)
                    setFilePk(res.filePk)
                })
                .catch((error) => console.log(error));
        }else {
        }
    }, [data,checks]);


    return (
        <div className='mt-24 center-flex flex-col'>
            {info.name}님의 서류 접수 항목은 아래와 같습니다.
            <div className={isMobi ? 'border w-[300px] py-2 center-flex flex-col mt-4 rounded-lg': 'border w-[400px] py-2 center-flex flex-col mt-4 rounded-lg'}>
                <div className='center-flex flex-col'>
                    {
                        data.docType &&
                        getDocType(data.docType).map((state, idx) =>
                            <div className='center-flex w-[200px]'>
                                <h2 className='mr-auto'>
                                    {idx + 1}. {state}
                                </h2>
                                <div className='ml-4'>
                                    <img src={checks ? checkOn : checkOff} alt={'check'} width={16}/>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default Text;
