import React, {useEffect, useState} from 'react';
import UseLogo from "../hooks/useLogo";
const TopBar: React.FC = () => {
    const isMobi = /Mobi/i.test(window.navigator.userAgent);
    const [width, setWidth] = useState(0);
    useEffect(() => {
        if(isMobi) {
            setWidth(250);
        }else {
            setWidth(300);
        }
    }, [isMobi]);
    return (
        <div className='topBar'>
            <UseLogo  width={width} />
        </div>
    );
};

export default TopBar;
