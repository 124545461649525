export function getParam (key : any) {
    return key.split('_');
}


export function getDocType(docType : string) {
    const doc = [
        {
            doc : ['운행기록 캡처본']
        },
        {
            doc : ['운행기록 캡처본2']
        }
    ]
        return doc[parseInt(docType) - 1].doc;
}
