import React, {useState, useEffect} from 'react';
import TopBar from "../component/topBar";
import Text from "../component/text";
import { SubmitHandler, useForm} from "react-hook-form";
import { ExtendedDataTypes} from "../@types";
import axios from "axios";

interface Inputs {
    image : any;
}

const MobiSubmit: React.FC<{ data: ExtendedDataTypes }> = ({ data }) => {
    const [check, setCheck] = useState(false);
    const [filePk, setFilePk] = useState('');
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: {
            image : ''
        },
        mode: 'onChange',
    });
    const [imagePreview, setImagePreview] = useState("");
    const upload = watch("image");
    useEffect(() => {
        if (upload && upload.length > 0) {
            setCheck(true);
            const file = upload[0];
            setImagePreview(URL.createObjectURL(file));
        }else {
            setCheck(false);
        }
    }, [upload]);
    const onSubmit: SubmitHandler<Inputs> = async (idx) => {
        try{
            const formData = new FormData();
            const type = idx.image[0].type.split('/');
            const modifiedFile = new File([idx.image[0]], data.upk + '_capcher.'+type[1]);

            formData.append('filePk', filePk);
            formData.append('upk', data.upk);
            formData.append('schema',data.schema);
            formData.append('images',modifiedFile);
            await axios.post('https://care.simg.kr/v1/api/careInsu/careData', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then((res) => {
                console.log(res.data.result);
                if(res.data.result === 'success') {
                    alert('접수 완료');
                }
            }).catch((err) => {
                alert('서비스 오류 발생');
            });
        }catch (e) {
            console.log(e);
        }
    }
    useEffect(() => {

    }, [filePk]);
    const onError = (errors: any) => {
        console.log(errors)
    };
    return (
        <div className='main'>
            <TopBar/>
            <Text data={data} checks={check} setFilePk={setFilePk}/>
            <form onSubmit={handleSubmit(onSubmit, onError)} encType="multipart/form-data">
                <div className='flex flex-col mt-8'>
                    <div className='mr-auto text-[16px] font-[600] pb-2'>
                        제출 서류
                    </div>
                    <div
                        className="w-[300px] h-40 rounded-lg border-2 border-dashed flex items-center justify-center">
                        <label htmlFor="picture" className="cursor-pointer text-center p-4 md:p-8">
                            {
                                imagePreview ?
                                    <>
                                        <img src={imagePreview} alt={'uploadFile'} className='w-[280px] h-[140px]'/>
                                    </>
                                    :
                                    <>
                                        <svg className="w-10 h-10 mx-auto" viewBox="0 0 41 40" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12.1667 26.6667C8.48477 26.6667 5.5 23.6819 5.5 20C5.5 16.8216 7.72428 14.1627 10.7012 13.4949C10.5695 12.9066 10.5 12.2947 10.5 11.6667C10.5 7.0643 14.231 3.33334 18.8333 3.33334C22.8655 3.33334 26.2288 6.19709 27.0003 10.0016C27.0556 10.0006 27.1111 10 27.1667 10C31.769 10 35.5 13.731 35.5 18.3333C35.5 22.3649 32.6371 25.7279 28.8333 26.5M25.5 21.6667L20.5 16.6667M20.5 16.6667L15.5 21.6667M20.5 16.6667L20.5 36.6667"
                                                stroke="#4F46E5" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                        </svg>
                                        <p className="mt-3 max-w-xs mx-auto">Click to <span
                                            className="font-medium text-indigo-600">Upload your  file</span> or drag
                                            and
                                            drop your file here</p>
                                    </>
                            }
                        </label>
                        <input {...register("image", {required: true})}
                               id="picture"
                               type="file"
                               accept="image/*"
                               className='hidden'
                               multiple={false}
                        />
                    </div>
                </div>
                <div className='fixed bottom-0 left-0 center-flex w-full py-4 bg-white'>
                    <button type="submit" className='button_on' >접수하기</button>
                </div>
            </form>
        </div>
    );
};

export default MobiSubmit;
