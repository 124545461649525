import React from 'react';
import './asset/styles/tailStyles.css';
import Main from "./pages/main";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotFound from "./pages/notFound";

function App() {

  return (
    <div className="App">
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Main />}></Route>
                <Route path="*" element={<NotFound />}></Route>
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
